import request from '@/utils/request'



const robotApi = {
	getMotorRecommendValue: '/wear-robot/biz/wmModel/getMotorRecommendValue', // 机器人行程
	getModel: '/wear-robot/biz/wmRobot/getModel', // 获取模型
	getJSONObjectList: '/wear-robot/biz/wmOperationRecord/getJSONObjectListByTag',

}
export function getMotorRecommendValue(parameter) {
	return request({
		url: robotApi.getMotorRecommendValue,
		method: 'post',
		data: parameter
	})
}

export function getModel(parameter) {
	return request({
		url: robotApi.getModel,
		method: 'get',
		params: parameter,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		}
	})
}

export function getJSONObjectList(parameter) {
	return request({
		url: robotApi.getJSONObjectList,
		method: 'post',
		data: parameter,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		}
	})
}