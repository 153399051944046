import {
	CRC16
} from "./crc16.js";
import {
	buf2hex,
	hexCharCodeToStr,
	decToHex
} from "./utils";
/**
 * @param {Object} register 寄存器地址
 * @param {Object} value    值
 * @param {Object} funcnum  功能码
 */
export const getBuff = (register, value, funcnum, address) => {

	if (register > 0xFFFF) {
		throw new Error('InvalidStartAddress');
	}
	if (!Number.isInteger(value) || value < 0 || value > 0xFFFF) {

		console.log("value===", value)

		throw new Error('InvalidValue');
	}

	const bodyPayload = Buffer.alloc(5);
	bodyPayload.writeUInt8(funcnum, 0);
	bodyPayload.writeUInt16BE(register, 1);
	bodyPayload.writeUInt16BE(value, 3);

	let crc = CRC16(Buffer.concat([Buffer.from([address]), bodyPayload]));
	const crBu = Buffer.alloc(2);
	crBu.writeUInt16LE(crc, 0);
	const idBuf = Buffer.from([address]);
	const payload = Buffer.concat([idBuf, bodyPayload, crBu]);
	return payload;
}


export const getReadInputRequestBuff = (register, values, funcnum, address) => {

	const bodyPayload = Buffer.alloc(5);
	bodyPayload.writeUInt8(funcnum, 0);
	bodyPayload.writeUInt16BE(register, 1);
	bodyPayload.writeUInt16BE(values, 3);

	let crc = CRC16(Buffer.concat([Buffer.from([address]), bodyPayload]));
	const crBu = Buffer.alloc(2);
	crBu.writeUInt16LE(crc, 0);
	const idBuf = Buffer.from([address]);
	const payload = Buffer.concat([idBuf, bodyPayload, crBu]);
	return payload;
}

export const getMultipleBuff = (register, value, funcnum, address) => {


	if (address > 0xFFFF) {
		throw new Error('InvalidStartAddress');
	}
	if (Array.isArray(value) && value.length > 0x7b) {
		throw new Error('InvalidArraySize');
	}
	if (value instanceof Buffer && value.length > 0x7b * 2) {
		throw new Error('InvalidBufferSize');
	}

	let byteCount;
	let numberOfBytes;
	let quantity;
	let valuesAsBuffer;
	let valuesAsArray = [];
	if (value instanceof Buffer) {
		byteCount = Math.min(value.length + 6, 0xF6);
		numberOfBytes = value.length;
		quantity = Math.floor(value.length / 2);
		valuesAsBuffer = value;

		for (let i = 0; i < value.length; i += 2) {
			valuesAsArray.push(value.readUInt16BE(i));
		}
	} else if (value instanceof Array) {
		valuesAsArray = value;
		//byteCount = Math.min(value.length * 2 + 6, 0xF6);
		numberOfBytes = Math.floor(value.length * 2);
		quantity = value.length; //decToHex(value.length);
		valuesAsBuffer = Buffer.alloc(numberOfBytes);
		value.forEach((v, i) => {

			valuesAsBuffer.writeUInt16BE(v, i * 2);
		});
	}
	console.log("valuesAsBuffer", valuesAsBuffer)
	//从机号1  功能码1 起始位置2  寄存器数目2 字节数目1  待写数据2N  crc2
	const bodyPayload = Buffer.alloc(6 + numberOfBytes);
	//功能码1 0x10
	bodyPayload.writeUInt8(funcnum, 0);
	//起始位置2  0100
	bodyPayload.writeUInt16BE(register, 1);
	//寄存器数目2  24
	bodyPayload.writeUInt16BE(quantity, 3);
	//字节数目1  24*2
	bodyPayload.writeUInt8(numberOfBytes, 5);
	//将valuesAsBuffer替换到bodyPayload中从位置6开始替换
	//待写数据2N
	valuesAsBuffer.copy(bodyPayload, 6);

	let crc = CRC16(Buffer.concat([Buffer.from([address]), bodyPayload]));
	const crBu = Buffer.alloc(2);
	crBu.writeUInt16LE(crc, 0);
	const idBuf = Buffer.from([address]);
	const payload = Buffer.concat([idBuf, bodyPayload, crBu]);




	return payload;
}
/**
 * @param {Object} register 寄存器地址
 * @param {Object} value    值
 * @param {Object} funcnum  功能码
 */
export const getResponseFromBuff = (buffer, funcnum, address) => {



	if (buffer instanceof Buffer) {
		//功能码
		const fc = buffer.readUInt8(0);
		//应答字节数
		const byteCount = buffer.readUInt8(1);
		//应答字节
		const payload = buffer.slice(2, 2 + byteCount);

		//转为string
		let utf8decoder = new TextDecoder();


		return utf8decoder.decode(payload);
	} else if (buffer instanceof Uint8Array) {

		let payload;
		let byteCount;
		if (buffer.length >= 5) {

			let valuesAsBuffer = Buffer.from(buffer);
			if (buffer[0] === address && buffer[1] === funcnum) {

				//1, 4, 10, 77, 65, 50, 52, 50, 50, 54, 48, 48, 49, 110, 139
				//功能码
				const fc = valuesAsBuffer.readUInt8(1);
				//应答字节数
				byteCount = valuesAsBuffer.readUInt8(2);
				//应答字节[1, 3, 2, 0, 2, 57, 133]
				payload = valuesAsBuffer.slice(3, 3 + byteCount);


			} else if (buffer[0] === funcnum) {

				//4, 10, 77, 65, 50, 52, 50, 50, 54, 48, 48, 49, 110, 139
				//功能码
				const fc = valuesAsBuffer.readUInt8(0);
				//应答字节数
				byteCount = valuesAsBuffer.readUInt8(1);
				//应答字节
				payload = valuesAsBuffer.slice(2, 2 + byteCount);
			}
			//
			if (byteCount <= 2) {

				let hexpayload = buf2hex(payload);

				return hexpayload;
			}

			//转为string文本解码
			let utf8decoder = new TextDecoder('utf-8');


			return utf8decoder.decode(payload);

		} else {

			return buffer[0].toString();
		}
	}
}