export default {
    "x4": {
        "key": "x4",
        "excelColumnIndex": 11,
        "ratio": 1,
        "title": "右肩头",
        "index": 1,
        "sum": 0
    },
    "x6": {
        "key": "x6",
        "excelColumnIndex": 12,
        "ratio": 1,
        "title": "右胸高",
        "index": 2,
        "sum": 10
    },
    "x1": {
        "key": "x1",
        "excelColumnIndex": 13,
        "ratio": 1,
        "title": "颈部",
        "index": 3,
        "sum": 2
    },
    "no1": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 4,
        "sum": 0
    },
    "x7": {
        "key": "x7",
        "excelColumnIndex": 14,
        "ratio": 1,
        "title": "前胸片",
        "index": 5,
        "sum": 0
    },
    "x3": {
        "key": "x3",
        "excelColumnIndex": 15,
        "ratio": 1,
        "title": "左肩头",
        "index": 6,
        "sum": 0
    },
    "x5": {
        "key": "x5",
        "excelColumnIndex": 16,
        "ratio": 1,
        "title": "左胸高",
        "index": 7,
        "sum": 0
    },
    "x8": {
        "key": "x8",
        "excelColumnIndex": 17,
        "ratio": 1,
        "title": "后胸片",
        "index": 8,
        "sum": 0
    },
    "x2": {
        "key": "x2",
        "excelColumnIndex": 18,
        "ratio": 1,
        "title": "挑肩部",
        "index": 9,
        "sum": 0
    },
    "no2": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 10,
        "sum": 0
    },
    "y6": {
        "key": "y6",
        "excelColumnIndex": 19,
        "ratio": 1,
        "title": "前腰下片",
        "index": 11,
        "sum": 0
    },
    "y4": {
        "key": "y4",
        "excelColumnIndex": 20,
        "ratio": 1,
        "title": "右腰片",
        "index": 12,
        "sum": 0
    },
    "y7": {
        "key": "y7",
        "excelColumnIndex": 21,
        "ratio": 1,
        "title": "后腰片",
        "index": 13,
        "sum": 0
    },
    "no3": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 14,
        "sum": 0
    },
    "y5": {
        "key": "y5",
        "excelColumnIndex": 22,
        "ratio": 1,
        "title": "前腰上片",
        "index": 15,
        "sum": 0
    },
    "no4": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 16,
        "sum": 0
    },
    "y2": {
        "key": "y2",
        "excelColumnIndex": 23,
        "ratio": 1,
        "title": "腰下高",
        "index": 17,
        "sum": 0
    },
    "y3": {
        "key": "y3",
        "excelColumnIndex": 24,
        "ratio": 1,
        "title": "左腰片",
        "index": 18,
        "sum": 0
    },
    "y1": {
        "key": "y1",
        "excelColumnIndex": 25,
        "ratio": 1,
        "title": "腰上高",
        "index": 19,
        "sum": 0
    },
    "no5": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 20,
        "sum": 0
    },
    "t2": {
        "key": "t2",
        "excelColumnIndex": 26,
        "ratio": 1,
        "title": "右臀片",
        "index": 21,
        "sum": 0
    },
    "t5": {
        "key": "t5",
        "excelColumnIndex": 27,
        "ratio": 1,
        "title": "后臀上片",
        "index": 22,
        "sum": 0
    },
    "t4": {
        "key": "t4",
        "excelColumnIndex": 28,
        "ratio": 1,
        "title": "前臀下片",
        "index": 23,
        "sum": 0
    },
    "no6": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 24,
        "sum": 0
    },
    "no7": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 25,
        "sum": 0
    },
    "no8": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 26,
        "sum": 0
    },
    "t3": {
        "key": "t3",
        "excelColumnIndex": 29,
        "ratio": 1,
        "title": "前臀上片",
        "index": 27,
        "sum": 0
    },
    "t1": {
        "key": "t1",
        "excelColumnIndex": 30,
        "ratio": 1,
        "title": "左臀片",
        "index": 28,
        "sum": 0
    },
    "t6": {
        "key": "t6",
        "excelColumnIndex": 31,
        "ratio": 1,
        "title": "后臀下片",
        "index": 29,
        "sum": 0
    },
    "no9": {
        "key": "",
        "excelColumnIndex": "",
        "ratio": 0,
        "title": "",
        "index": 30,
        "sum": 0
    }
}