<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {
    console.log(navigator)
    if ("serial" in navigator) {
      this.$store.commit("updateSupport", true);
      this.$store.commit("listenersSerialPort");
      // The Web Serial API is supported.
    } else {
      this.$store.commit("updateSupport", false);
      this.$message.error(this.errorMsg);
    }
    this.$store.commit("updateEventBus", this.$EventBus);
  },
  beforeDestroy() {
    this.$store.commit("removeListenersSerialPort");
  },
};
</script>

<style lang="less">
@import "./assets/styles.css";
@import url("https://at.alicdn.com/t/font_2258137_2as5arfov38.css");
@import "./assets/wangEdit.css";
html {
  background: #fbf8fb;
  width: 100%;
  height: 100%;
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
