
// 将Uint8Array转为十六进制
export function buf2hex (buffer) {
    return Array.prototype.map
        .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
        .join('')
}

// 将Uint8Array转为十六进制
export function Decoding8arr (uint8array) {
    return new TextDecoder('utf-8').decode(uint8array)
}

// 十六进制字符串转字符串
export function hexCharCodeToStr (hexCharCodeStr) {
    var trimmedStr = hexCharCodeStr.trim()
    var rawStr =
        trimmedStr.substr(0, 2).toLowerCase() === '0x'
            ? trimmedStr.substr(2)
            : trimmedStr
    var len = rawStr.length
    if (len % 2 !== 0) {
        alert('Illegal Format ASCII Code!')
        return ''
    }
    var curCharCode
    var resultStr = []
    for (var i = 0; i < len; i = i + 2) {
        curCharCode = parseInt(rawStr.substr(i, 2), 16) // ASCII Code Value
        resultStr.push(String.fromCharCode(curCharCode))
    }
    // console.log('resultStr', resultStr)
    //   resultStr.forEach((item) => {
    //     console.log(item, item.includes('8F'))
    //     if (item.includes('8F')) {
    //       console.log(item)((item = ' '))
    //     }
    //   })
    return resultStr.join('')
}

// 将十六进制转为 Uint8Array
export function Encoding8arr (myString) {
    return new TextEncoder('utf-8').encode(myString)
}

// 十进制转为带0X前缀的十六进制
export function decToHex (dec) {
    let hex = dec.toString(16)
    let result = '0X'
    hex.length == 1 ? (result = result + '0' + hex) : (result = result + hex)
    return result
}