import {
	buf2hex,
	hexCharCodeToStr,
	decToHex
} from "../utils/utils";
import {
	message
} from "ant-design-vue";
//import inputObject from "../utils/config";
import inputObject1 from "../utils/config1";
import inputObject2 from "../utils/config2";
import inputObject3 from "../utils/config3";
import {
	addSubLog,
	updateStatus,
	getTypeModel
} from "../../api/collect";
import {
	createSubOrder
} from "@/api/collect";
import {
	getBuff,
	getMultipleBuff,
	getResponseFromBuff
} from "../utils/modbusUtils.js";
import femaleRulerJson from '@/config/femaleRuler.json';
import maleRulerJson from '@/config/maleRuler.json';
import {
	convertUnit
} from "../utils/convert";
import {
	getMotorRecommendValue,
	getModel
} from "@/api/newrobot";

const serialPort = {
	state: {
		MODBUS_SLAVE_ID: 1,
		SN: '',
		isBatch: false,
		modelInfo: '',
		motorConfigList: [],
		motorArray: [],
		transDateArr: [],
		isRequesting: false,
		step: 0, // 步骤值
		inputObject: '',
		inputObject1: inputObject1,
		inputObject2: inputObject2,
		inputObject3: inputObject3,
		inputObject123: {
			"x4": {
				"key": "x4",
				"excelColumnIndex": 11,
				"ratio": 1,
				"title": "右肩头",
				"index": 1,
				"sum": 0
			},
			"x6": {
				"key": "x6",
				"excelColumnIndex": 12,
				"ratio": 1,
				"title": "右胸高",
				"index": 2,
				"sum": 10
			},
			"x1": {
				"key": "x1",
				"excelColumnIndex": 13,
				"ratio": 1,
				"title": "颈部",
				"index": 3,
				"sum": 2
			},
			"no1": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 4,
				"sum": 0
			},
			"x7": {
				"key": "x7",
				"excelColumnIndex": 14,
				"ratio": 1,
				"title": "前胸片",
				"index": 5,
				"sum": 0
			},
			"x3": {
				"key": "x3",
				"excelColumnIndex": 15,
				"ratio": 1,
				"title": "左肩头",
				"index": 6,
				"sum": 0
			},
			"x5": {
				"key": "x5",
				"excelColumnIndex": 16,
				"ratio": 1,
				"title": "左胸高",
				"index": 7,
				"sum": 0
			},
			"x8": {
				"key": "x8",
				"excelColumnIndex": 17,
				"ratio": 1,
				"title": "后胸片",
				"index": 8,
				"sum": 0
			},
			"x2": {
				"key": "x2",
				"excelColumnIndex": 18,
				"ratio": 1,
				"title": "挑肩部",
				"index": 9,
				"sum": 0
			},
			"no2": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 10,
				"sum": 0
			},
			"y6": {
				"key": "y6",
				"excelColumnIndex": 19,
				"ratio": 1,
				"title": "前腰下片",
				"index": 11,
				"sum": 0
			},
			"y4": {
				"key": "y4",
				"excelColumnIndex": 20,
				"ratio": 1,
				"title": "右腰片",
				"index": 12,
				"sum": 0
			},
			"y7": {
				"key": "y7",
				"excelColumnIndex": 21,
				"ratio": 1,
				"title": "",
				"index": 13,
				"sum": 0
			},
			"no3": {
				"key": "no3",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "后腰片",
				"index": 14,
				"sum": 0
			},
			"y5": {
				"key": "y5",
				"excelColumnIndex": 22,
				"ratio": 1,
				"title": "前腰上片",
				"index": 15,
				"sum": 0
			},
			"no4": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 16,
				"sum": 0
			},
			"y2": {
				"key": "y2",
				"excelColumnIndex": 23,
				"ratio": 1,
				"title": "腰下高",
				"index": 17,
				"sum": 0
			},
			"y3": {
				"key": "y3",
				"excelColumnIndex": 24,
				"ratio": 1,
				"title": "左腰片",
				"index": 18,
				"sum": 0
			},
			"y1": {
				"key": "y1",
				"excelColumnIndex": 25,
				"ratio": 1,
				"title": "腰上高",
				"index": 19,
				"sum": 0
			},
			"no5": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 20,
				"sum": 0
			},
			"t2": {
				"key": "t2",
				"excelColumnIndex": 26,
				"ratio": 1,
				"title": "右臀片",
				"index": 21,
				"sum": 0
			},
			"t5": {
				"key": "t5",
				"excelColumnIndex": 27,
				"ratio": 1,
				"title": "后臀上片",
				"index": 22,
				"sum": 0
			},
			"t4": {
				"key": "t4",
				"excelColumnIndex": 28,
				"ratio": 1,
				"title": "前臀下片",
				"index": 23,
				"sum": 0
			},
			"no6": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 24,
				"sum": 0
			},
			"no7": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 25,
				"sum": 0
			},
			"no8": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 26,
				"sum": 0
			},
			"t3": {
				"key": "t3",
				"excelColumnIndex": 29,
				"ratio": 1,
				"title": "前臀上片",
				"index": 27,
				"sum": 0
			},
			"t1": {
				"key": "t1",
				"excelColumnIndex": 30,
				"ratio": 1,
				"title": "左臀片",
				"index": 28,
				"sum": 0
			},
			"t6": {
				"key": "t6",
				"excelColumnIndex": 31,
				"ratio": 1,
				"title": "后臀下片",
				"index": 29,
				"sum": 0
			},
			"no9": {
				"key": "",
				"excelColumnIndex": "",
				"ratio": 0,
				"title": "",
				"index": 30,
				"sum": 0
			}
		},
		writer: null,
		reader: null,
		popreader: null,

		port: null, // 连接的串口
		isConnect: false, // 是否连接
		equipmentInfo: "", // 设备信息
		dataSource: [], //源数据
		code: "", // 存储接收到的数据的指令
		sendData: [], // 发送的数据
		receiveData: [], // 接收的数据
		hexMessage: "", // 接收到的完整16进制字符串
		checkResults: false, // 检验结果
		isAwait: false, // 是否处于等待接收数据状态（断包时记录该状态）
		timerOut: null, // 数据超时无响应
		timer: null,
		support: false, // 是否支持navigator.serial 串口通讯
		isStop: false, // 机器人是否处于暂停状态
		orderInfo: {
			//选择的订单信息
			id: "", //订单id
			sn: "", //订单号
			size: "", //码数
		},
		subOrder: [], //创建的子订单
		currentData: [], // 当前执行的形变值
		orderTimestamp: [], // 订单时间戳
		EventBus: null, //  事件车对象
	},

	mutations: {


		// 保存EventBus
		updateEventBus(state, EventBus) {
			state.EventBus = EventBus;
		},
		// 监听串口的连接的断开
		listenersSerialPort(state) {
			navigator.serial.addEventListener("connect", (event) => {
				message.success("串口设备已插入");
				clearInterval(state.timer);
				state.timer = null;
				// TODO: Automatically open event.target or warn user a port is available.
			});

			navigator.serial.addEventListener("disconnect", (event) => {
				// state.isConnect = true
				message.error("串口设备已拔出");
				clearInterval(state.timer);
				state.timer = null;
				// console.log('串口已断开')
				// TODO: Remove |event.target| from the UI.
				// If the serial port was opened, a stream error would be observed as well.
			});
		},
		// 移除监听
		removeListenersSerialPort() {
			navigator.serial.removeEventListener("connect", (event) => {
				message.success("串口设备已插入");
				// TODO: Automatically open event.target or warn user a port is available.
			});

			navigator.serial.removeEventListener("disconnect", (event) => {
				// state.isConnect = true
				message.error("串口设备已拔出");
				// console.log('串口已断开')
				// TODO: Remove |event.target| from the UI.
				// If the serial port was opened, a stream error would be observed as well.
			});
		},

		// 修改浏览器是否支持串口通讯的标识
		updateSupport(state, flag) {
			state.support = flag;
		},
		updateinputObject(state, id) {
			//获取模型
			getTypeModel().then((res) => {

				console.log('updateinputObject.getTypeModel', res)

				if (res.code == 200) {

					for (let jjj = 0; jjj < res.data.length; jjj++) {
						//通过id选择模型
						if (res.data[jjj].id == id) {
							//通过id加载配置
							if (id == 4) {
								state.inputObject = state.inputObject123
							} else if (id == 1) {
								//state.inputObject = res.data[jjj].maps;
								state.inputObject = state.inputObject1
							} else if (id == 2) {
								//state.inputObject = res.data[jjj].maps;
								state.inputObject = state.inputObject2
							} else if (id == 3) {
								//state.inputObject = res.data[jjj].maps;
								state.inputObject = state.inputObject3
							} else {
								state.inputObject = state.inputObject123
							}

						}
					}

				}
			})

		},

		// 添加表格数据
		addDataSource(state, obj) {

			let data = obj.data;
			let modelType = obj.modelType;

			state.dataSource = [];
			data.forEach((item, index) => {
				item.step = index + 1;
				item.key = index + 1;
			});

			if (modelType === 'old') {

				state.dataSource = data;
				this.dispatch("createSubOrderFun");
			} else {
				//清空变形数据  

				//根据子订单获取体型值
				let motorArray = [];
				let transDateArr = [];
				let newDataSource = [];
				for (let i = 0; i < data.length; i++) {
					let d = data[i];
					let modelData = {}
					let ds = {};
					ds.key = i + 1;
					ds.step = i + 1;
					//颈围
					//旧模数据 modelData.neck_circumference = d.neck ? d.neck : 380;
					modelData.neck_circumference = d.neck_circumference ? d.neck_circumference * 10 : 380;
					//肩宽
					//modelData.shoulder_width = +d.shoulder * 10 < 400? 400: +d.shoulder * 10; //400
					modelData.shoulder_width = d.shoulder_width ? +d.shoulder_width * 10 : 400; //400
					ds.shoulder = d.shoulder_width;
					//上臂
					//modelData.upper_arm_circumference = d.arm ? +d.arm * 10 : 220; //220;
					modelData.upper_arm_circumference = d.upper_arm_circumference ? +d.upper_arm_circumference *
						10 : 220; //220;
					ds.arm = d.upper_arm_circumference;
					//胸围
					//modelData.chest_circumference =+d.bust * 10 < 900 ? 900: +d.bust * 10 ; //900;
					modelData.chest_circumference = d.chest_circumference ? +d.chest_circumference * 10 : 900;
					ds.bust = d.chest_circumference;
					//腰
					//modelData.waist_circumference =  +d.waist* 10 < 790 ? 790: +d.waist* 10 ; //790;
					modelData.waist_circumference = d.waist_circumference ? +d.waist_circumference * 10 : 790;
					ds.waist = d.waist_circumference;
					//臀
					//modelData.hip_circumference =  +d.hipline * 10 < 890 ? 890: +d.hipline; //890;
					modelData.hip_circumference = d.hip_circumference ? +d.hip_circumference * 10 : 890;
					ds.hipline = d.hip_circumference;
					//上身
					//modelData.upper_body_length =  +d.length * 10 < 690? 690 : +d.length * 10; // 690;
					modelData.upper_body_length = d.upper_body_length ? +d.upper_body_length * 10 : 690;
					ds.length = d.upper_body_length;
					motorArray.push(modelData);
					newDataSource.push(ds);
					let transDate = {};
					transDate.model_data = modelData;
					//console.log(d.travel.split(','))
					// if (d.travel && d.travel != "") {
					// 	transDate.motor_data = d.travel.split(',').map((item) => item * 1);
					// } else {
					// 	transDate.motor_data = [];
					// }

					transDateArr.push(transDate)


				}
				state.dataSource = newDataSource;
				state.motorArray = motorArray;
				state.transDateArr = transDateArr;

				this.dispatch("getModelInfo");
			}

		},
		// 删除表格数据
		deleteDataSource(state, key) {
			let index = state.dataSource.findIndex((item) => {
				return item.key == key;
			});
			let list = state.dataSource;
			list.splice(index, 1);
			state.dataSource = [];
			state.dataSource.push(...list);
		},
		// 修改表格数据
		modifyDataSource(state, obj) {
			let {
				index,
				data
			} = obj;
			let list = state.dataSource;
			list.splice(index, 1, data);
			state.dataSource = [];
			state.dataSource.push(...list);
		},
		// 接收数据
		receiveDataFuc(state, value) {
			// value 是一个 Uint8Array
			let hexStr = buf2hex(value);

			let msg = "";
			this.commit("dataValidation", hexStr);

			if (state.isAwait) {
				return;
			}
			if (!state.checkResults) {
				state.sendData = [];
				state.receiveData = [];
				if (state.code == "0c") {
					this.dispatch("submitData");
				} else {
					message.error("指令执行失败，请尝试从新发送");
				}
				return;
			}
			console.log("state.hexMessage", state.hexMessage, hexStr);
			msg = state.hexMessage.slice(6, state.hexMessage.length - 4);
			console.log("指令类型", state.code);
			console.log("返回的信息", msg);
			console.log('时间!!!!!!!!!!!!!!-接受数据数据，', state.code, new Date(), Date.now().toString().substr(-3, 3))
			switch (state.code) {
				case "06": // 获取设备信息
					let info = hexCharCodeToStr(msg);
					state.equipmentInfo = info;
					let arr = info.split(" ");
					state.equipmentInfo = arr.join(" ");
					console.log("arr", arr, state.equipmentInfo);
					state.receiveData = [];
					break;
				case "0a": // 接收霍尔传感器数据
					console.log("霍尔数据", msg);
					// 偶数圈发送拍摄型号
					// 当前圈数等于总圈数发送结束信号
					let data1 = msg.slice(0, 2);
					let data2 = msg.slice(2, 4);
					let data3 = msg.slice(4, 6);
					let data4 = msg.slice(6, msg.length);
					let turns = parseInt(data1, 16) * 256 + parseInt(data2, 16);
					let turnsCount = parseInt(data3, 16) * 256 + parseInt(data4, 16);

					state.receiveData = [];
					this.dispatch("takingPicturesFunc", {
						turns,
						turnsCount
					});

					break;
				case "10": // 复位机器人
					message.success("机器人已经复位");
					state.receiveData = [];
					state.isStop = true;
					break;
				case "11": // 机器人开始工作
					message.success("机器人已经开始工作");
					state.receiveData = [];
					state.isStop = false;
					break;
				case "12": // 机器人暂停工作
					message.success("机器人已经暂停工作");
					state.receiveData = [];
					state.isStop = true;
					break;
				case "0c": // 收到的形变数据
					this.checkResults = false;
					state.sendData = [];
					state.receiveData = [];
					state.step = state.step + 1;
					if (state.step <= state.dataSource.length - 1) {
						this.dispatch("submitData");
					} else {
						clearTimeout(state.timerOut);
						state.timerOut = null;
						message.success("数据传输完毕");
					}
					break;
			}
		},
		newRobotReceiveDataFuc(state, value) {
			console.log("value", value);
			console.log("state", state);
			let msg = "";
			console.log("newRobotReceiveDataFuc.isAwait", state.isAwait);
			if (state.isAwait) {
				return;
			}
			let that = this;
			msg = getResponseFromBuff(value, 0x03, state.MODBUS_SLAVE_ID)
			console.log("返回msg", msg);
			if (state.funcName === 'readExcuteFormIndex') {

				console.log("readExcuteFormIndex", msg);

				let index = +msg;
				if (index > 0) {
					this.commit("updateCurrentData", {
						currentData: state.dataSource[index - 1],
						type: "update",
					})
				}
				if (index >= state.transDateArr.length - 1) {

					//数据采集完成更新采集状态
					setTimeout(function() {

						if (state.timer) {
							clearInterval(state.timer);
							state.timer = null;
						}

						that.dispatch("updateStatusFunc");

						that.dispatch('robotReset', 'new');
					}, 15000);




				}

			}




		},
		initStep(state) {

			state.step = 0;
			state.transDateArr = []

		},
		// 数据校验
		dataValidation(state, hexStr) {
			let startStr = hexStr.slice(0, 2);
			let endStr = hexStr.slice(hexStr.length - 2, hexStr.length);
			if (
				startStr == "55" &&
				(endStr == "0a" || endStr == "0d") &&
				hexStr.length > 4
			) {
				this.commit("lengthValidation", hexStr);
			} else if (startStr == "55" && endStr != "0a" && endStr != "0d") {
				console.log("帧头部分");
				state.isAwait = true;
				state.receiveData.push(hexStr);
			} else if (
				startStr != "55" &&
				endStr != "0a" &&
				endStr != "0d" &&
				state.receiveData.length > 0
			) {
				console.log("收到中间部分");
				state.receiveData.push(hexStr);
			} else {
				console.log("收到帧尾部分");
				if (state.receiveData.length > 0) {
					state.receiveData.push(hexStr);
					let receiveDataStr = state.receiveData.join("");
					this.commit("lengthValidation", receiveDataStr);
				}
			}
		},
		// 数据长度校验
		lengthValidation(state, hexStr) {
			console.log("hexStr", hexStr, hexStr.length);
			state.code = hexStr.slice(2, 4);
			let receiveDataLength = 0;
			switch (state.code) {
				case "06": // 获取设备信息
					console.log("设备信息长度校验");
					receiveDataLength = 31 * 2;
					break;
				case "0a": // 接收霍尔传感器数据
					console.log("霍尔数据长度校验");
					receiveDataLength = 9 * 2;
					break;
				case "10": // 复位机器人
					console.log("复位机器人长度校验");
					receiveDataLength = 5 * 2;
					break;
				case "11": // 机器人开始工作
					console.log("机器人开始工作长度校验");
					receiveDataLength = 5 * 2;
					break;
				case "12": // 机器人暂停工作
					console.log("机器人暂停工作长度校验");
					receiveDataLength = 5 * 2;
					break;
				case "0c": // 收到的形变数据
					console.log("形变数据长度校验");
					receiveDataLength = state.sendData.length * 2;
					break;
			}
			console.log(hexStr.length, receiveDataLength);
			// hexStr.length == receiveDataLength
			//   ? (state.checkResults = true)
			//   : (state.checkResults = false);
			if (hexStr.length == receiveDataLength) {
				state.checkResults = true
				state.isAwait = false;
			} else {
				state.checkResults = false
				state.isAwait = true;
			}

			state.hexMessage = hexStr;
			//  state.isAwait = false;
		},
		// 表格数据重置
		dataReset(state) {
			state.dataSource = [];
			state.step = 0;
		},
		// 更新选择的订单号
		setOrderInfo(state, orderInfo) {
			state.orderInfo = Object.assign({}, state.orderInfo, orderInfo);
		},
		// 更新子订单列表
		setSubOrder(state, subOrder) {
			state.subOrder = subOrder;
		},
		// 更新步骤值
		updateStep(state, step) {
			state.step = step;
		},

		// 更新当前的数据值
		updateCurrentData(state, {
			currentData,
			type
		}) {
			state.currentData = [];
			if (type == "reset") {
				return;
			}
			state.currentData.push(currentData);
		},
		// 记录订单时间戳
		updateOrderTimestamp(state, {
			type,
			data
		}) {
			if (type == "add") {
				// 添加
				state.orderTimestamp.push(data);
			}
			if (type == "reset") {
				// 重置
				state.orderTimestamp = [];
			}
		},
		newrobotStar(state, modelType) {
			if (!state.isConnect) {
				message.error("请先连接设备");
				return;
			}
			console.log("执行机器人开始工作指令");


			if (state.step < state.transDateArr.length - 1) {

				message.error("正在数据采集，请稍后再试")
				return;
			}

			if (state.transDateArr && state.transDateArr.length > 1) {

				state.isBatch = true;
			} else {
				state.isBatch = false;
			}

			if (state.isBatch) {
				this.dispatch('startBatchTransform');
			} else {

				this.dispatch('startTransform');
			}


			// 更新当前发送的数据
			this.commit("updateCurrentData", {
				currentData: [],
				type: "reset"
			});
			//不间断请求设备
			let that = this;
			state.timer = setInterval(async function() {

				let index = await that.dispatch("readExcuteFormIndex");
				console.log("当前执行======", index);



			}, 3000);


		},


	},

	actions: {
		// 连接(关闭)串口
		async connect({
			state
		}, modelType) {
			if (!state.support) {
				message.error(
					"浏览器不支持串口服务，请使用chrome浏览器（浏览器版本：89+）"
				);
				return;
			}
			if (state.isConnect) {
				await state.reader.cancel();
				await state.writer.close();
				await state.port.close();
				state.isConnect = false;
				message.success("已经断开与串口设备的连接");
				return;
			}
			// 提示用户选择一个串口
			state.port = await navigator.serial.requestPort();

			try {
				// 等待串口打开  (baudRate：波特率)
				let baudRate = 19200;
				let parity = "even";
				if (modelType == 'old') {
					baudRate = 115200;
					parity = "none"; // 奇偶校验
				} else {
					baudRate = 19200;
					parity = "even";
				}

				await state.port.open({
					baudRate: baudRate,
					dataBits: 8, // 数据位
					stopBits: 1, // 停止位
					parity: parity, // 奇偶校验
				});
				state.isConnect = true;
				message.success("串口设备已连接");
				// console.log('port', port)
				state.writer = state.port.writable.getWriter();
				// 串行写入数据
				if (modelType === 'old') {
					state.reader = state.port.readable.getReader();
				} else {
					//const textDecoder = new TextDecoderStream();
					//const readableStreamClosed =  state.port.readable.pipeTo(textDecoder.writable);
					//state.reader = textDecoder.readable.getReader();

					state.reader = state.port.readable.getReader();
				}

				//获取旧机器人信息，新机器人SN
				this.dispatch("getEquipmentInfo", modelType);

				// console.log('port.readable', port.readable)

				// 监听来自串行设备的数据
				while (true) {
					const {
						value,
						done
					} = await state.reader.read();
					console.log('时间!!!!!!!!!!!!!!-只要有数据，无论对不对，都打印时间接受数据数据，', value, new Date(), Date.now()
						.toString().substr(-3, 3))

					//旧机器人监听
					if (modelType === 'old') {
						this.commit("receiveDataFuc", value);
					} else if (modelType === 'new') {
						this.commit("newRobotReceiveDataFuc", value);
					}

					if (done) {
						clearInterval(state.timer);
						state.timer = null;
						// 允许稍后关闭串口。
						state.reader.releaseLock();
						state.reader.cancel();
						break;
					}
				}
			} catch (error) {

				console.log(error)
				message.error("串口打开失败，请检查串口是否已经被占用");
				clearInterval(state.timer);
				state.reader.releaseLock();
				state.reader.cancel();
				state.timer = null;
			}
		},
		// 发送数据
		async submitData({
			state,
			commit
		}) {
			if (state.dataSource.length == 0) {
				message.error("请添加数据");
				return;
			}
			// 更新当前发送的数据
			commit("updateCurrentData", {
				currentData: [],
				type: "reset"
			});
			clearTimeout(state.timerOut);
			state.timerOut = null;
			//   inputObject数据重置
			for (let dataKey in state.inputObject) {
				state.inputObject[dataKey].sum = 0;
			}

			let commonArray = ["0X55", "0X0C", "0X40"];
			let stepArray = ["0X00", decToHex(state.step + 1)];
			//   let currentStep =
			//   stepArray.push(currentStep)
			let allStepArray = ["0X00", decToHex(state.dataSource.length)];

			commonArray.push(...[...stepArray, ...allStepArray]);

			let inputData = [];
			// 将表格的数据与inputData数据对应
			for (let dataKey in state.dataSource[state.step]) {
				//  alert(dataKey)
				state.inputObject[dataKey] ?
					(state.inputObject[dataKey].sum =
						state.dataSource[state.step][dataKey]) :
					"";
			}
			//  转换inputObject的数据
			for (let dataKey in state.inputObject) {
				let hex = decToHex(state.inputObject[dataKey].sum);
				inputData.push("0X00", hex);
			}

			commonArray.push(...inputData);

			// 校验和
			let result = 0;
			commonArray.forEach((item) => {
				result = result + parseInt(item);
			});
			let resultHex = decToHex(result);
			commonArray.push(resultHex);

			commonArray.push("0X0D"); // 结尾

			console.log("commonArray", commonArray);

			state.sendData = [];
			state.sendData.push(...commonArray);

			console.log(commonArray)
			let data = new Uint8Array(commonArray);
			await state.writer.write(data);
			state.timerOut = setTimeout(() => {
				message.error("响应超时，请检查设备");
			}, 10000);
		},
		// 获取设备信息
		async getEquipmentInfo({
			state
		}, modelType) {
			console.log("获取设备信息");

			if (modelType == 'old') {
				let data = new Uint8Array([
					"0X55",
					"0X05",
					"0X00",
					"0X00",
					"0X5A",
					"0X0D",
				]);
				await state.writer.write(data);
			} else {
				let sn = await this.dispatch("readDeviceSN");
				await this.dispatch("getModelId")
			}
		},
		// 机器人复位
		async robotReset({
			state
		}, modelType) {
			if (!state.isConnect) {
				message.error("请先连接设备");
				return;
			}

			if (modelType === 'new') {
				let param = {};
				param.register = 0x0002;
				param.value = 3;
				console.log("复位执行");
				await this.dispatch('sendCmd', param);
			} else if (modelType === 'old') {
				let data = new Uint8Array([
					"0X55",
					"0X10",
					"0X00",
					"0X00",
					"0X65",
					"0x0D",
				]);
				await state.writer.write(data);
			}
		},
		// 机器人开始工作
		async robotStar({
			state
		}, modelType) {
			if (!state.isConnect) {
				message.error("请先连接设备");
				return;
			}
			console.log("执行机器人开始工作指令");
			if (modelType === 'old') {
				let data = new Uint8Array([
					"0X55",
					"0X11",
					"0X00",
					"0X00",
					"0X66",
					"0x0D",
				]);
				console.log(data);
				console.log('时间!!!!!!!!!!!!!!-机器人开始工作，', new Date(), Date.now().toString().substr(-3, 3))
				await state.writer.write(data);
			} else if (modelType === 'new') {

				if (state.step < state.transDateArr.length - 1) {

					message.error("正在数据采集，请稍后再试")
					return;
				}

				if (state.transDateArr && state.transDateArr.length > 1) {

					state.isBatch = true;
				} else {
					state.isBatch = false;
				}
				console.log("开始执行了------------------", state.transDateArr);


				console.log("state.isBatch====", state.isBatch);
				if (state.isBatch) {
					this.dispatch('startBatchTransform');
				} else {

					this.dispatch('startTransform');
				}

				// 更新当前发送的数据
				this.commit("updateCurrentData", {
					currentData: [],
					type: "reset"
				});
				//不间断请求设备
				let that = this;
				state.timer = setInterval(async function() {

					let index = await that.dispatch("readExcuteFormIndex");
					console.log("当前执行======", index);
				}, 2000);


			}
		},
		// 机器人暂停工作
		async robotPause({
			state
		}, modelType) {
			if (!state.isConnect) {
				message.error("请先连接设备");
				return;
			}
			console.log("执行机器人暂停工作指令");
			if (modelType === 'old') {
				let data = new Uint8Array([
					"0X55",
					"0X12",
					"0X00",
					"0X00",
					"0X67",
					"0x0D",
				]);
				console.log('时间!!!!!!!!!!!!!!-机器人暂停工作，', new Date(), Date.now().toString().substr(-3, 3))
				await state.writer.write(data);
			} else {
				let that = this;
				if (state.isBatch) {
					let param = {};
					param.register = 0x0019;
					param.value = 0;
					//只触发变形，不触发拍照
					this.dispatch('sendCmd', param);

					let param2 = {};
					param2.register = 0x0018;
					param2.value = 8000;
					//定时变形触发间隔

					state.timerOut = setTimeout(() => {
						that.dispatch('sendCmd', param2);
					}, 40);

					let param3 = {};
					param3.register = 0x0001;
					param3.value = 0x0004;
					//批量时停止旋转 设置成定时触发
					state.timerOut = setTimeout(() => {
						that.dispatch('sendCmd', param3);
					}, 80);

				} else {
					let param = {};
					param.register = 0x0001;
					param.value = 0;

					this.dispatch('sendCmd', param);
				}
			}
		},
		// 拍摄照片
		takingPicturesFunc({
			state,
			commit
		}, turnNumber) {
			let {
				turns,
				turnsCount
			} = turnNumber;

			let action = turns % 2 == 0 ? "start" : "stop";
			let index = turns % 2 == 0 ? (turns - 4) / 2 : (turns - 3) / 2;
			let maxCount = turnsCount + 4;

			console.log(
				"当前圈数",
				turns,
				"返回总圈数",
				turnsCount,
				"实际总圈数",
				maxCount
			);
			//    console.log('时间!!!!!!!!!!!!!!-拍摄照片，',new Date(),Date.now().toString().substr(-3,3))
			if (turns >= 3 && turns < 4) {
				index = 0;
				// 更新当前发送的数据
				commit("updateCurrentData", {
					currentData: state.dataSource[index],
					type: "update",
				});
			}
			if (turns < 4) {
				// this.dispatch("robotReset");
				return;
			}
			if (index > state.subOrder.length - 1) {
				index = state.subOrder.length - 1;
			}

			// 更新当前发送的数据
			commit("updateCurrentData", {
				currentData: state.dataSource[index],
				type: "update",
			});
			// 记录订单时间戳
			if (turns % 2 == 0) {
				commit("updateOrderTimestamp", {
					type: "add",
					data: {
						order_sn: state.subOrder[index].sub_order_id,
						time: JSON.stringify(new Date().getTime()),
					},
				});
			}

			// if (index == state.subOrder.length - 1) {
			//   this.dispatch("updateStatusFunc");
			// }
			// if (turns >= maxCount) {
			//   action = "stop";
			//   index = state.subOrder.length - 1;
			// }
			if (turns == maxCount) {
				//alert('dengyutruns')
				this.dispatch("updateStatusFunc");
			}

			// this.dispatch("takingPicturesRequest", { action, index });
		},
		// 采集子订单时间戳
		addSubLogFuntion({
			state,
			commit
		}) {
			addSubLog({
				data: state.orderTimestamp,
			}).then((res) => {
				if (res.code == 200) {
					message.success("采集完成");
					commit("updateOrderTimestamp", {
						type: "reset",
					});
				} else {
					message.error(res.msg);
				}
			});
		},
		// 更新订单状态
		updateStatusFunc({
			state,
			dispatch
		}) {
			console.log("数据采集完成，更新订单状态=============>");
			//let orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
			updateStatus({
				order_id: state.orderInfo.id,
				order_status: 2,
			}).then((res) => {
				console.log("更新状态", res);
				if (res.code == 200) {

					console.log("state.EventBus", state.EventBus);
					dispatch("addSubLogFuntion");
					state.EventBus.$emit("completeModal");
				} else {
					message.error(res.msg);
				}
			});
		},


		// 发送数据
		createSubOrderFun({
			state,
			commit,
			dispatch
		}) {
			if (state.dataSource.length == 0) {
				message.error("请先上传数据");
				return;
			}
			let size_ids = [];
			state.dataSource.forEach((item) => {
				size_ids.push(item.size_id * 1);
			});
			createSubOrder({
					size_ids,
					order_id: state.orderInfo.id,
				})
				.then((res) => {
					console.log("创建子订单", res);
					if (res.code == 200) {
						commit("updateStep", 0); // 重置步骤值
						commit("setSubOrder", res.data);
						dispatch("submitData");
					}
				})
				.catch((error) => {
					console.log("adfafa");
					message.error(error);
				});
		},
		async sendCmd({
			state
		}, param) {

			let data = getBuff(param.register, param.value, 0x06, state.MODBUS_SLAVE_ID)
			await state.writer.write(data);


		},
		async sendReadCmd({
			state
		}, param) {


			let data = getBuff(param.register, param.value, 0x03, state.MODBUS_SLAVE_ID)
			//let data = getBuff(param.register, param.value,0x04,state.MODBUS_SLAVE_ID)
			await state.writer.write(data);
			state.isRequesting = false;
		},
		async sendMultiplCmd({
			state
		}, param) {


			let data = getMultipleBuff(param.register, param.value, 0x10, state.MODBUS_SLAVE_ID)

			// let dataString = "" ;
			// for (var i = 0; i < data.length; i++) {
			//     dataString += data[i].toString(16)+ " ";
			//   }

			await state.writer.write(data);


			state.isRequesting = false;
		},
		async readCmd({
			state
		}, param) {


			if (state.isRequesting) {
				// 如果当前已有请求在进行中，则等待请求完成
				await new Promise((resolve) => setTimeout(resolve, 100));

				return this.dispatch('readCmd', param);
			}

			try {
				//发送读指令
				await this.dispatch('sendReadCmd', param);
				state.isRequesting = false;

				//const { metrics, request, response } =
				//await this.client.readInputRegisters(register, readCount);
				//return response.body.valuesAsArray;
			} catch (error) {
				throw error;

			} finally {
				state.isRequesting = false;
			}
		},
		/**
		 * 新机器获取设备状态
		 *  - bit0-bit1：1正在复位 2表示变形中，0表示未变形
		    - bit2：1表示旋转中，0表示未旋转
		    - bit3：1表示拍照中，0表示未拍照
		 * @param callback
		 */
		async readDeviceStatus({
			state
		}) {

			try {

				let param = {};
				param.register = 0x0008;
				param.value = 1;
				const resp = await this.dispatch('readCmd', param);
				while (true) {
					const {
						value,
						done
					} = await state.reader.read();
					if (done) {

						state.reader.releaseLock();
						state.reader.cancel();

						break;
					}
					//处理数据
					console.log("新机器获取设备状态==================================");
					console.log(value); //数据Uint8Array类型
					// 允许稍后关闭串口
					let resetting = (value[0] & 0x01) === 0x01;
					let transform = (value[0] & 0x02) === 0x02;
					let rotate = (value[0] & 0x04) === 0x04;
					let take_photo = (value[0] & 0x08) === 0x08;
					let timer_wait = (value[0] & 0x10) === 0x10;

					return {
						resetting: resetting,
						transform: transform,
						rotate: rotate,
						take_photo: take_photo,
						timer_wait: timer_wait,
						//rotate_level: this.currentRotateLevel,
						//is_batch: this.isBatch,
						//camera_enable:this.cameraModeEnable,
						//job_excuting:this.jobExcuting
					};
				}

				//       if (!resp) {
				//         console.log("modbus无响应 resp=" + resp);

				//         return;
				//       }


			} catch (error) {
				state.reader.cancel();
				return null;
				throw error;
			}

		},
		async getExecuteIndex({
			state
		}, currentMotorStatus) {

			let excuteIndex = await this.dispatch('readExcuteFormIndex');
			console.log("excuteIndex", excuteIndex)
			if (currentMotorStatus.resetting) {
				return excuteIndex;
			}
			return excuteIndex - 1;

		},
		/**
		 * 当前形状编号
		 */
		async readExcuteFormIndex({
			state
		}) {


			let param = {};
			param.register = 0x000b;
			param.value = 1;
			let that = this;
			try {
				const resp = await that.dispatch('readCmd', param);

				state.funcName = 'readExcuteFormIndex';

			} catch (error) {
				state.reader.cancel();
				throw error;
			}



		},

		/*
		 *单次变形
		 *设置电机的行程值，单位为mm(单次执行时调用)
		 *@param position  第1个索引为数据的索引(最多支持24个电机)
		 */
		async setMotorData({
			state
		}, position) {

			state.isBatch = false;
			//第一步复位
			//await this.dispatch('robotReset','new');
			//变形电机位置  
			//0x0100 第一个变形状态下第一个电机位置
			//0x0101 第一个变形状态下第2个点机位置
			let register = 0x0100; // + 0 * 0x0100;
			//当前形状编号
			//await this.dispatch('setExecuteIndex',0);
			//设置一次变形 第二步向寄存器0x0000写入变形个数N

			console.log("setMotorData register=" + register + " data:" + position);
			let positionData = convertUnit(position);

			let param2 = {};
			param2.register = register;

			param2.value = positionData;

			console.log("positionDatar=", positionData);
			let that = this;
			await this.dispatch('setExecuteIndex', 0);
			//待优化-----------机器返回后再执行下一个指令

			await that.dispatch('configTransformDataCount', 1);

			//await this.dispatch('setExecuteIndex',0);
			// 0x0000 1
			//await this.dispatch('configTransformDataCount',1);
			//3.位机依次将N个变形状态的电机位置写入寄存器0xNNxx

			await that.dispatch('sendMultiplCmd', param2);

			//await this.dispatch('sendMultiplCmd', param2);

			//4.上位机向寄存器0x0001~0x0003写入相应的旋转/触发、变形、拍照命令
			//0x0002  1

			//不拍照
			this.dispatch('configCamera', 0);

			//let currentMotorStatus =  this.dispatch("readDeviceStatus");
			//console.log('currentMotorStatus', currentMotorStatus)

		},

		/**
		 * 批量设置电机的行程值，单位为cm(批量执行时使用)
		 * @param position  第1个索引为数据的索引(最多支持64条)，第2个索引为电机的索引（最大支持24个电机）
		 */
		async setBatchMotorData({
			state
		}, position) {
			state.isBatch = true;

			//当前形状编号
			await this.dispatch('setExecuteIndex', 0);

			let that = this;
			//设置一次变形 第二步向寄存器0x0000写入变形个数N
			setTimeout(() => {
				that.dispatch('configTransformDataCount', position.length);
			}, 20);

			for (let i = 0; i < position.length; i++) {

				setTimeout(() => {

					let register = 0x0100 + i * 0x0100;
					let positionData = convertUnit(position[i]);

					let param2 = {};
					param2.register = register;
					param2.value = positionData;

					that.dispatch('sendMultiplCmd', param2);
				}, 40 * (i + 1));
			}

		},

		async startBatchTransform() {

			let that = this;
			//await this.dispatch('robotReset', 'new');
			//this.dispatch('configTransform', 2); //执行并旋转

			//变形开关0002

			that.dispatch('configTransform', 2);

			//旋转2*N+1周
			setTimeout(() => {
				let param2 = {};
				param2.register = 0x0001;
				param2.value = 2;
				that.dispatch('sendCmd', param2);
			}, 30);

			//拍照0003
			setTimeout(() => {
				that.dispatch('configCamera', 2);
			}, 60);
		},
		async startTransform() {

			this.dispatch('configTransform', 1);

		},

		async setTransformData({
			state
		}, obj) {
			//obj = Object.assign({},obj)
			let data = obj.data;
			let model = obj.model;

			if (!data) {
				this.$message.error("变形数据为空");
				return;

			}
			state.statusArray = []; //清空
			state.currentMode = model;


			for (let i = 0; i < data.length; i++) {
				let motorData = data[i].motor_data; //模型数据
				let modelData = data[i].model_data; //体型数据
				state.statusArray.push({
					motor_data: motorData,
					model_data: modelData,
					model_status: {}, //模型状态
				});
			}


			if (data.length > 1) {


				let totalMotorData = [];
				for (let i = 0; i < data.length; i++) {
					totalMotorData.push(data[i].motor_data);
				}
				state.isBatch = true;
				this.dispatch('setBatchMotorData', totalMotorData);
			} else if (data.length == 1) {

				state.isBatch = false;
				this.dispatch('setMotorData', data[0].motor_data);
			}
		},

		/**
		 *
		 * @param mode
		 - 0x0000：不拍照
		 - 0x0001：单次拍照，持续1（？）秒，然后清零寄存器，若在命令0x0002或0x0003下收到此命令，则在当前拍照命令基础上额外拍照1（？）秒，然后清零寄存器
		 - 0x0002：旋转触发拍照，收到旋转触发信号后拍照
		 - 0x0003：持续拍照，拍照IO持续为高
		 */
		configCamera(mode) {

			let param2 = {};
			param2.register = 0x0003;
			param2.value = mode;
			this.dispatch('sendCmd', param2);
		},

		/**
		 * 设置变形数据条目数
		 * @param count 变形数据条目数
		 */
		async configTransformDataCount({
			state
		}, count) {

			let param2 = {};
			param2.register = 0x0000;
			param2.value = count;
			console.log("configTransformDataCount", param2)
			this.dispatch('sendCmd', param2);


		},
		async configTransform({
			state
		}, mode) {
			let param2 = {};
			param2.register = 0x0002;
			param2.value = mode;
			console.log("configTransform", param2)

			this.dispatch('sendCmd', param2);

		},

		async setExecuteIndex({
			state
		}, index) {
			let param2 = {};
			param2.register = 0x000b;
			param2.value = index;

			console.log("setExecuteIndex", param2)
			this.dispatch('sendCmd', param2);
		},
		/**
		 *
		 * @param mode
		 - 0x0000：不拍照
		 - 0x0001：单次拍照，持续1（？）秒，然后清零寄存器，若在命令0x0002或0x0003下收到此命令，则在当前拍照命令基础上额外拍照1（？）秒，然后清零寄存器
		 - 0x0002：旋转触发拍照，收到旋转触发信号后拍照
		 - 0x0003：持续拍照，拍照IO持续为高
		 */
		async configCamera({
			state
		}, mode) {

			let param2 = {};
			param2.register = 0x0003;
			param2.value = mode;
			console.log("configCamera", param2)

			this.dispatch('sendCmd', param2);

		},

		async readDeviceSN({
			state
		}) {

			let param = {};
			param.register = 0x0011;
			param.value = 5;

			try {
				const resp = await this.dispatch('readCmd', param);
				while (true) {
					const {
						value,
						done
					} = await state.reader.read();
					if (done) {
						if (state.reader) {
							state.reader.releaseLock();
							//state.reader.cancel();
						}
						break;
					}

					console.log("readDeviceSN.value =", value);
					//处理数据
					let sn = getResponseFromBuff(value, 0x03, state
						.MODBUS_SLAVE_ID);
					//错误码重新获取
					if (sn == 1) {
						this.dispatch('readDeviceSN');
					}

					console.log("readDeviceSN =", sn);
					state.SN = sn;

					// 允许稍后关闭串口
					if (!value) {
						return '';
					}
					return sn;
				}
			} catch (error) {
				if (state.reader) {
					state.reader.releaseLock();
					//state.reader.cancel();
				}
				throw error;
			}

		},
		async getModelId({
			state
		}) {

			console.log("执行getModelId====")


			if (!state.SN || state.SN == '') {
				await this.dispatch("readDeviceSN");
			}

			if (!state.SN || state.SN == '') {
				message.error(`设备读取失败请重新连接`);
				return;
			}

			let param = {};
			param.var = state.SN;
			getModel(param).then((res) => {

				if (res.success) {


					state.modelInfo = res.result;
					state.motorConfigList = res.result
						.wmMotorConfigList;

				} else {
					message.error(`获取模型信息失败`);

				}


			}).catch(e => {
				console.log(e)

			})


		},

		async getModelInfo({
			state
		}) {

			if (!state.SN || state.SN == '') {
				await this.dispatch("readDeviceSN");
			}

			if (!state.SN || state.SN == '') {
				message.error(`设备读取失败请重新连接`);
				return;
			}

			if (!state.modelInfo || state.modelInfo == "") {

				await this.dispatch('getModelId');

			}



			//创建体型策略
			let model_data = [];
			if (state.motorArray) {
				//组合数据
				state.getDataSource = [];
				for (let i = 0; i < state.motorArray
					.length; i++) {
					let item = {};
					item.size_id = i;
					state.getDataSource.push(item)
				}

				for (let i = 0; i < state.motorArray
					.length; i++) {

					//新获取行程数据
					await this.dispatch("getmotorData", {
						part: state.motorArray[i],
						modelInfo: state.modelInfo,
						index: i,
						length: state.motorArray.length
					});


				}

			}

			if (state.transDateArr) {

				console.log("setTransformData===========", state
					.transDateArr)
				this.dispatch("setTransformData", {
					data: state.transDateArr,
					model: state.modelInfo
				});



			} else {
				message.error(`行程数据为空`);
			}

		},

		async getmotorData({
			state
		}, obj) {


			let part = obj.part;
			let modelType = state.modelInfo.modelType;
			let index = obj.index;
			let length = obj.length;
			//获取变形数据
			let param = {};
			//param.modelId = this.modeln.model;
			param.modelId = state.modelInfo.id;
			//param.modelId = "1702987914675568642";
			param.part = part;
			//年龄模式（默认：0；青年：-1；中老年：-2；自定义：25~85实际年龄值）
			param.ageType = 0;
			//最大误差值（mm）5~20
			param.maxSizeError = 20;
			//操作模式（直播模式live/打版模式tailor）			
			param.model = "live";
			//是否返回计算日志			
			param.logEnable = false;
			if (modelType == "male_upper_body_model") {

				param.ruler = maleRulerJson;
			} else {
				param.ruler = femaleRulerJson;
			}
			return getMotorRecommendValue(param).then((
				res) => {
				state.step = index + 1;

				if (res.success) {
					if (res.result) {
						//电机对应行程
						let position = res.result
							.motorTravel;

						if (position) {
							let transDate = {};
							transDate.model_data =
								part;
							transDate.motor_data =
								position;
							state.transDateArr[
									index] =
								transDate

						}

						if (index == length - 1) {

							message.success(
								`数据传输成功`);
						}

						console.log(
							"getMotorRecommendValue",
							state.transDateArr)

					} else {



						message.error(`获取第` + (
								index + 1) +
							`条变形策略失败，共` +
							length + `条数据`);
					}

				} else {

					message.error(`获取第` + (index +
							1) + `条变形策略失败，共` +
						length + `条数据`);
				}

			});


		},
		// 拍照的请求
		// takingPicturesRequest({ state }, { action, index }) {
		//   // action,
		//   // ucode: state.subOrder[index].ordersn,
		//   // ordersn: state.orderInfo.sn
		//   // "action": "start",
		//   // "ucode": "202107300935228765",
		//   // "ordersn": "202108181600001234",
		//   // axios.get(`http://127.0.0.1:7999/CamServer?action=start&ucode=202107300935228766&ordersn=202108181600001234`).then(res => {
		//   // axios
		//   //   .get(
		//   //     `http://127.0.0.1:7999/CamServer?action=${action}&ucode=${state.subOrder[index].ordersn}&ordersn=${state.orderInfo.sn}`
		//   //   )
		//   //   .then((res) => {
		//   //     console.log("拍照", res);
		//   //     console.log('res == "{Result : OK}"', res.data == "{Result : OK}");
		//   //     if (res.data == "{Result : OK}") {
		//   //       console.log(`相机${action}`);
		//   //     } else {
		//   //       this.dispatch("takingPicturesRequest", { action, index });
		//   //       console.log("拍照数据错误");
		//   //     }
		//   //     // if(res.data.)
		//   //   })
		//   //   .catch((error) => {
		//   //     console.log("拍照错误", error);
		//   //   });
		//   // takingPictures({
		//   //     action,
		//   //     ucode: state.subOrder[index].ordersn,
		//   //     ordersn: state.orderInfo.sn
		//   //     // "action": "start",
		//   //     // "ucode": "202107300935228765",
		//   //     // "ordersn": "202108181600001234",
		//   // }).then(res => {
		//   //     console.log('拍照', res)
		//   //     if (res == "Not Found") {
		//   //         this.dispatch("takingPicturesRequest", { action, index })
		//   //     } else {
		//   //         console.log(`相机${action}`)
		//   //     }
		//   //     // if(res.data.)
		//   // }).catch(error => {
		//   //     console.log("拍照错误", error)
		//   // })
		// },
		// 采集子订单拍照数据
	},
	getters: {
		// 获取表格数据
		getDataSource: (state) => state.dataSource,
		// 获取inputObject
		getInputObject: (state) => state.inputObject,
		// 获取连接状态
		getIsConnect: (state) => state.isConnect,
		// 获取设备信息
		getEquipmentInfo: (state) => state.equipmentInfo,
		// 获取步骤值
		getStep: (state) => state.step,
		// 获取选择的订单信息
		getOrderInfo: (state) => state.orderInfo,
		// 获取当前形变数据
		getCurrentData: (state) => state.currentData,

		getSN: (state) => state.SN,

		getModelId: (state) => state.modelInfo.id


	},
};

export default serialPort;