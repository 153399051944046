import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import {
	VueAxios
} from './axios'
import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import {
	BASE_URL
} from "./common"
// 创建 axios 实例
const request = axios.create({
	// API 请求的默认前缀
	baseURL: BASE_URL,
	timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
	console.log(error)
	if (error.response) {
		const data = error.response.data
		// 从 localstorage 获取 token
		const token = storage.get(ACCESS_TOKEN)
		if (error.response.status === 403) {
			notification.error({
				message: 'Forbidden',
				description: data.message
			})
		}
		if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
			notification.error({
				message: 'Unauthorized',
				description: 'Authorization verification failed'
			})
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		}
	}
	return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
	const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN") ? JSON.parse(localStorage.getItem(
		"ACCESS_TOKEN")) : ""
	// 如果 token 存在
	// 让每个请求携带自定义 token 请根据实际情况自行修改
	if (ACCESS_TOKEN) {
		config.headers['AdminToken'] = ACCESS_TOKEN.token
		config.headers['AdminUserId'] = ACCESS_TOKEN.userId
		config.headers['X-Access-Token'] = ACCESS_TOKEN.token
	} else {
		config.headers['X-Access-Token'] = '1'
	}




	return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
	if (response.data.code == 401) {
		window.location = '/login'
	}
	return response.data
}, errorHandler)

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export {
	installer as VueAxios,
	request as axios
}