import request from "@/utils/request";

const collectApi = {
  pay:'/admin/Order/rechargeList',
  collectType: "/admin/Order/model", // 采样类别
  collectDate: "/admin/Order/date", // 预约日期
  collectTime: "/admin/Order/times", // 预约钟点
  collectInfo: "/admin/Order/orderInfo", // 登记/输入确认码
  collectAdd: "/admin/Order/orderAdd", // 开始采集
  collectList: "/admin/Order/orderList", // 采集列表
  createSizeDetail: "/admin/Order/createSizeDetail", // 电机参数入库
  createSubOrder: "/admin/Order/createSubOrder", // 创建子订单
  takingPictures: "/admin/Order/photo", // 拍照
  orderWork: "/admin/Order/orderWork", // 采集工作区列表
  updateStatus: "/admin/Order/updateStatus", // 采集订单状态更新
  addSubLog: "/admin/Order/addSubLog", // 采集子订单拍照数据
  model: "/admin/AdminTool/list", // 采样机器人
 typeModel: "/admin/RobotType/list", // 采样类型
 //model: "/index/Index/robot", // 采样机器人
  date: "/admin/Order/date", // 预约日期
  times: "/admin/Order/times", //预约终点
  confirmOrder: "/admin/Order/confirm", // 开启登记确认
  autoCreateSubOrder: "/admin/Order/auto", // 自动生成子订单
  repateStatus:"/admin/Order/operate", //重拍
  getOrderStatus: '/admin/order/getOrderStatus', //获取单个订单状态

  refundInfo:'/admin/order/refundInfo',                     //退款信息
  passRefund:'/admin/order/passRefund',                       //同意退款
  refuseRefund :'/admin/order/refuseRefund',                     //拒绝退款
  getShapeList:'/admin/order/getShapeList',

  thaliList:'/admin/SizeGoods/list',
  thaliInfo:'/admin/SizeGoods/info',
  thaliAdd:'/admin/SizeGoods/add',
  thaliEdit:'/admin/SizeGoods/edit',
  thaliUpdownGoods:'/admin/SizeGoods/upDownGoods',

};
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */
export function thaliAdd(parameter) {
  return request({
    url: collectApi.thaliAdd,
    method: "post",
    data: parameter,
  });
}
export function thaliEdit(parameter) {
  return request({
    url: collectApi.thaliEdit,
    method: "post",
    data: parameter,
  });
}
export function thaliUpdownGoods(parameter) {
  return request({
    url: collectApi.thaliUpdownGoods,
    method: "post",
    data: parameter,
  });
}

export function thaliList(parameter) {
  return request({
    url: collectApi.thaliList,
    method: "get",
    params: parameter,
  });
}
export function thaliInfo(parameter) {
  return request({
    url: collectApi.thaliInfo,
    method: "get",
    params: parameter,
  });
}






//退款信息
export function pay(parameter) {
  return request({
    url: collectApi.pay,
    method: "get",
    params: parameter,
  });
}
export function refundInfo(parameter) {
  return request({
    url: collectApi.refundInfo,
    method: "get",
    params: parameter,
  });
}
//同意退款
export function passRefund(parameter) {
  return request({
    url: collectApi.passRefund,
    method: "post",
    data: parameter,
  });
}
//拒绝退款
export function refuseRefund(parameter) {
  return request({
    url: collectApi.refuseRefund,
    method: "post",
    data: parameter,
  });
}
//获取单个订单状态
 export function getOrderStatus(parameter) {
  return request({
    url: collectApi.getOrderStatus,
    method: "get",
    params: parameter,
  });
}
//改为重新拍摄
 export function repateStatus(parameter) {
  return request({
    url: collectApi.repateStatus,
    method: "post",
    data: parameter,
  });
}
// 获取采样类别
export function getCollectType(parameter) {
  return request({
    url: collectApi.collectType,
    method: "get",
    params: parameter,
  });
}
// 提交采样类别
export function postCollectType(parameter) {
  return request({
    url: collectApi.collectType,
    method: "post",
    data: parameter,
  });
}
export function collectDate(parameter) {
  return request({
    url: collectApi.collectDate,
    method: "get",
    params: parameter,
  });
}
export function collectTime(parameter) {
  return request({
    url: collectApi.collectTime,
    method: "get",
    params: parameter,
  });
}
// 获取确认码
export function getCollectInfo(parameter) {
  return request({
    url: collectApi.collectInfo,
    method: "get",
    params: parameter,
  });
}
// 登记
export function postCollectInfo(parameter) {
  return request({
    url: collectApi.collectInfo,
    method: "post",
    data: parameter,
  });
}
// 开始采集
export function collectAddget(parameter) {
  return request({
    url: collectApi.collectAdd,
    method: "get",
    params: parameter,
  });
}
export function collectAdd(parameter) {
  return request({
    url: collectApi.collectAdd,
    method: "post",
    data: parameter,
  });
}


export function getShapeList(parameter) {

  return request({
    url: collectApi.getShapeList,
    method: "get",
    params: parameter,
  });
}
// 采集列表
export function collectList(parameter) {
  return request({
    url: collectApi.collectList,
    method: "get",
    params: parameter,
  });
}

// 电机数据入库
export function createSizeDetail(parameter) {
  return request({
    url: collectApi.createSizeDetail,
    method: "post",
    data: parameter,
  });
}

// 创建子订单
export function createSubOrder(parameter) {
  return request({
    url: collectApi.createSubOrder,
    method: "post",
    data: parameter,
  });
}

// 拍摄照片
export function takingPictures(parameter) {
  return request({
    url: collectApi.takingPictures,
    method: "get",
    params: parameter,
  });
}

// 采集工作区列表
export function orderWork(parameter) {
  return request({
    url: collectApi.orderWork,
    method: "get",
    params: parameter,
  });
}

// 采集订单状态更新
export function updateStatus(parameter) {
  return request({
    url: collectApi.updateStatus,
    method: "post",
    data: parameter,
  });
}

// 采集子订单拍照数据
export function addSubLog(parameter) {
  return request({
    url: collectApi.addSubLog,
    method: "post",
    data: parameter,
  });
}

// 采样类别
export function getModel(parameter) {
  return request({
    url: collectApi.model,
    method: "get",
    params: parameter,
  });
}


export function getTypeModel(parameter) {
  return request({
    url: collectApi.typeModel,
    method: "get",
    params: parameter,
  });
}
// 预约日期
export function getDate(parameter) {
  return request({
    url: collectApi.date,
    method: "get",
    params: parameter,
  });
}
// 预约钟点
export function getTimes(parameter) {
  return request({
    url: collectApi.times,
    method: "get",
    params: parameter,
  });
}
// 确认订单
export function confirmOrder(parameter) {
  return request({
    url: collectApi.confirmOrder,
    method: "post",
    data: parameter,
  });
}

// 自动生成子订单
export function autoCreateSubOrder(parameter) {
  return request({
    url: collectApi.autoCreateSubOrder,
    method: "post",
    data: parameter,
  });
}
