import {
	buf2hex,
	hexCharCodeToStr,
	decToHex
} from "./utils";

export function convertUnit(position) {


	for (let i = 0; i < position.length; i++) {
		position[i] = +position[i] * 100; //转化为单位0.01mm
	}
	return position;
}